<template>
  <v-container fluid class="pa-0">
    <Workflow :flowId="props.flowId" :key="props.flowId" />
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";

export default {
  components: {
    Workflow,
  },
  props: {
    flowId: {
      type: String,
    },
  },
  setup(props) {
    return {
      props,
    };
  },
};
</script>
